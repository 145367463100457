/*CORE*/
import React, { useEffect, useState } from 'react'
/*LIBS*/
import { RangeValue } from 'rc-picker/lib/interface'
import { bindActionCreators, Dispatch } from 'redux'
import { Button, DatePicker, message } from 'antd'
import { connect } from 'react-redux'
import { AxiosResponse } from 'axios'
import moment from 'moment'
/*ACTIONS*/
import { getPartnershipMetricsByRangeDate as getPartnershipMetricsByRangeDateAction } from 'store/analytics/actions'
import {
  exportOrderEmail,
  updateUI as updateUIAction,
} from "store/partnerships/actions"
/*TYPES*/
import { IPartnershipDetails } from 'types/Partnerships'
import { ExportOrderEmailPayload, UpdateUiAction } from 'store/partnerships/types'
import { IMetrics } from 'types/Metrics'
import { RootState } from 'types'
/*COMPONENTS*/
import AppSkeleton from '../../components/Skeletons/AppSkeleton'
import CurrenciesTable from '../components/CurrenciesTable/CurrenciesTable'
import ExportCSVModal from '../components/ExportCSVModal/ExportCSVModal'
import AnalyticsOverallTable from '../components/AnalyticsOverallTable/AnalyticsOverallTable'
/*SELECTORS*/
import { uiSelector } from 'store/partnerships/selectors'
/*ASSETS*/
import { ReactComponent as ArrowLeft } from 'assets/img/arrow-right.svg'
/*UTILS*/
import { normalizeMetrics } from 'utils/utils'
import { combineMetrics } from 'utils/analytics'
/*STYLES*/
import styles from './PartnershipAnalytics.module.scss'


const { RangePicker } = DatePicker

interface IStateProps {
  ui: {
    csvModal: boolean
    sentEmail: boolean
  }
}

interface IDispatchProps {
  getPartnershipMetricsByRangeDate: (
    miniAppId: string | number,
    hostAppId: string | number,
    range: [moment.Moment, moment.Moment],
    isMiniApp: boolean
  ) => Promise<AxiosResponse<IMetrics[]>>
  updateUI: (payload: UpdateUiAction['payload']) => void
  exportOrderEmail: (payload: ExportOrderEmailPayload) => Promise<void>
}

interface Props extends IDispatchProps, IStateProps {
  partnership: IPartnershipDetails
}

const PartnershipAnalytics = ({ ui, getPartnershipMetricsByRangeDate, partnership, updateUI, exportOrderEmail }: Props) => {
  const [metrics, setMetrics] = useState<IMetrics | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [dateRange, setDateRange] =
    useState<RangeValue<moment.Moment>>([moment().startOf('month'), moment().endOf('month')])

  useEffect(() => {
    loadMetrics(partnership, dateRange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange?.[0], dateRange?.[1]])

  const loadMetrics = async (partnership: IPartnershipDetails, dateRange: RangeValue<moment.Moment>) => {
    if (dateRange?.[0] && dateRange?.[1]) {
      try {
        setIsLoading(true)
        const isMiniApp = !partnership.is_my_integration
        const range: [moment.Moment, moment.Moment] = [dateRange[0], dateRange[1]]

        const metricsResponse = await getPartnershipMetricsByRangeDate(partnership.miniapp.id, partnership.hostapp.id, range, isMiniApp)
        setMetrics(combineMetrics(normalizeMetrics(metricsResponse.data)))
        setIsLoading(false)

      } catch (e) {
        setIsLoading(false)
        message.error('Something went wrong while loading analytics')
      }
    }
  }

  const onChangeDatePicker = (values: RangeValue<moment.Moment>) => {
    setDateRange(values)
  }

  return (
    <div className={styles['partnership-analytics']}>

      {isLoading || !metrics ? (
        <AppSkeleton />
      ) : (
        <>
          <div className={styles['date-picker-wrapper']}>
            <RangePicker
              bordered={false}
              suffixIcon={null}
              value={dateRange}
              allowClear={false}
              format={'DD/MM/YYYY'}
              onChange={onChangeDatePicker}
              className={styles['date-picker']}
              separator={<ArrowLeft className={styles['separator']} />}
            />
            {
              partnership.miniapp.category?.name === 'eSIM' && (
                <Button
                  type='link'
                  onClick={() => updateUI({ csvModal: true })}
                >Export Orders</Button>
              )
            }
          </div>
          <AnalyticsOverallTable metrics={metrics} />
          <CurrenciesTable metrics={metrics} />
          <ExportCSVModal
            visible={ui.csvModal}
            width='750px'
            description='Analytics data will be emailed to you in CSV format.'
            handleClose={() => updateUI({ csvModal: false })}
            handleSubmit={async ({ email }: { email: string }) => {
              try {
                await exportOrderEmail({
                  email,
                  from_date: dateRange?.[0]?.format('YYYY-MM-DD') || '',
                  to_date: dateRange?.[1]?.format('YYYY-MM-DD') || '',
                  miniapp_id: partnership.miniapp.id,
                  hostapp_id: partnership.hostapp.id,
                })
                updateUI({ csvModal: false, sentEmail: true })
              } catch (e) {
                message.error(e?.response?.data?.detail ?? 'Sorry. Something went wrong.')
              }
            }}
          />
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    ui: uiSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators<any, any>(
    {
      getPartnershipMetricsByRangeDate: getPartnershipMetricsByRangeDateAction,
      updateUI: updateUIAction,
      exportOrderEmail,
    },
    dispatch
  ),
})

export default connect<IStateProps, IDispatchProps>(mapStateToProps, mapDispatchToProps)(PartnershipAnalytics)
